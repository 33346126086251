<template>

  <div class="row" ref="uploadForm">

    <div class="col-xs-10 offset-xs-1 col-md-10 offset-md-1 col-sm-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">
            {{ formTitle }}
          </h4>
          <l-button @click="reset" type="danger" outline wide>
                <span class="btn-label">
                    <i class="fa fa-close"></i>
                </span>
            Reset
          </l-button>&nbsp;
        </div>


        <div class="card-body">
          <div class="custom-file">
            <input type="file" ref="fileUpload" accept=".csv,.xlsx,.excel,.xls" @change="onChange"
                   class="custom-file-input" id="customFile">
            <label class="custom-file-label" for="customFile">{{ fileName }}</label>
          </div>

          <transition name="slide">
            <column-chooser
              ref="columnChooser"
              v-if="showColumnChooser"
              :excel-columns="excelColumns"
              :system-columns="systemColumns">
            </column-chooser>
          </transition>
        </div>

        <div class="card-footer text-right">
          <l-button
            :disabled="entityNotFoundError"
            @click.prevent="handleSubmit()"
            type="info" wide>Submit
          </l-button>&nbsp;

          <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel
          </l-button>
        </div>

      </card>
    </div>
  </div>

</template>
<script>
import parseFile from '@/parser';
import ColumnChooser from '@/components/ColumnChooser';

export default {

  components: {
    ColumnChooser
  },

  data() {
    return {
      formTitle: "Upload Shipping File",
      fileName: "Choose File",
      file: null,
      excelColumns: null,
      systemColumns: null,
      showColumnChooser: false,
      project: null,
      entityNotFoundError: false
    };
  },


  mounted() {
    this.pageBuilder();
  },

  methods: {

    pageBuilder() {

      this.loader = this.$loading.show({
        container: this.$refs.uploadForm
      });

      this.axios.get("projects-shipping-data/upload-data/builder/" + this.$route.params['id'])
        .then(response => {
          this.project = response.data.project;
          this.systemColumns = response.data.systemColumns;
          this.loader.hide();
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.$notify({
              message: "Project Not Found",
              timeout: 2000,
              type: 'danger'
            });
            this.entityNotFoundError = true;
            this.loader.hide();
          } else {
            console.log(e.response);
          }
        });
    },

    onChange(event) {
      this.file = event.target.files[0] ? event.target.files[0] : this.file;

      if (this.file) {
        this.fileName = this.file.name;
        parseFile(this.file).then((results) => {

          let tempExcelColumns = results.map(row => {
            return row.name;
          });

          if (this.checkForDuplicates(tempExcelColumns)) { //check if there are duplicated columns
            this.$notify({
              message: "You have duplicated headers please fix that and upload the file",
              timeout: 6000,
              type: 'danger'
            });
            return;
          }

          //assign excel columns after checking
          this.excelColumns = tempExcelColumns;
          this.showColumnChooser = true;

        }).catch((error) => {
          this.reset();
          this.$notify({
            message: error,
            timeout: 6000,
            type: 'danger'
          });
        });
      }
    },

    checkForDuplicates(array) {
      return new Set(array).size !== array.length
    },

    reset() {
      this.$refs.fileUpload.value = null;
      this.file = null;
      this.fileName = "Choose File";
      this.excelColumns = null;
      this.showColumnChooser = false;
    },

    async handleSubmit() {
      if (this.file != null) {
        if (this.$refs.columnChooser.validate()) {

          this.loader = this.$loading.show({
            container: this.$refs.uploadForm
          });

          let bodyFormData = new FormData();
          bodyFormData.append('matchingData', JSON.stringify(this.$refs.columnChooser.result));
          bodyFormData.append('file', this.file);

          try {
            let response = await this.axios.post("projects-shipping-data/upload-data/" + this.project.id,
              bodyFormData, {headers: {"Content-Type": "multipart/form-data"}});

            this.$notify({
              message: 'Shipping File Uploaded Successfully',
              timeout: 1000,
              type: 'success'
            });

            this.$router.push("/projects/list");

          } catch (e) {
            this.$notify({
              message: e.response.data.message,
              timeout: 10000,
              type: 'danger'
            });
          } finally {
            this.loader.hide();
          }
        }
      }
    }

  }

}
</script>

<style scoped lang="scss">

.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  animation: slide-in .5s ease-in forwards;
  transition: opacity .3s;
}

.slide-leave-active {
  animation: slide-out .5s ease-in forwards;
  transition: opacity .3s;
  opacity: 0;
}

@keyframes slide-in {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

</style>
