<template>
  <div class="row" ref="table">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Shipping Data</h4>

          <div>

            <router-link v-if="$store.getters['auth/checkAccess']('projects-shipping-data/add-row')"
                         :to="'/projects/add-shipping-data/'+$route.params['id']+''"
                         class="btn btn-success btn-wd">
              Add New Record
              <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
            </router-link>&nbsp;

            <l-button
              v-if="$store.getters['auth/checkAccess']('projects-shipping-data/change-status')"
              class="mr-lg-1" @click="openChangeStatusModal()"
              :disabled="((numberOfSelectedRows === 0) || preventActions)" type="info">
              Change Status
            </l-button>

            <l-button
              v-if="$store.getters['auth/checkAccess']('projects-shipping-data/activate-records')"
              class="mr-lg-1" @click="activateSelected"
              :disabled="((numberOfSelectedRows === 0) || preventActions)" type="success">
              Activate
            </l-button>

            <l-button
              v-if="$store.getters['auth/checkAccess']('projects-shipping-data/deactivate-records')"
              class="mr-lg-1" @click="deactivatedSelected"
              :disabled="((numberOfSelectedRows === 0) || preventActions)" type="danger">
              Deactivate
            </l-button>

            <el-popconfirm
              confirmButtonText='OK'
              cancelButtonText='No, Thanks'
              icon="el-icon-info"
              iconColor="red"
              @confirm="deleteSelected"
              title="Are you sure to delete this record(s)?">
              <l-button
                v-if="$store.getters['auth/checkAccess']('projects-shipping-data/delete-records')"
                slot="reference" class="mr-lg-1"
                :disabled="((numberOfSelectedRows === 0) || preventActions)"
                type="primary">Delete
              </l-button>
            </el-popconfirm>


            <l-button
              v-if="$store.getters['auth/checkAccess']('projects-shipping-data/delete-all-records')"
              :disabled="preventActions"
              @click="openDeleteAllModal"
              class="mr-lg-1" type="primary">Delete All
            </l-button>



          </div>

        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">

          <div class="form-group col-md-2 col-sm-12">
            <el-select
              class="select-default"
              v-model="filters.activationStatus"
              clearable
              placeholder="Activation Status">
              <el-option
                v-for="item in filterBuilders.activationStatus"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group col-md-2 col-sm-12">
            <el-select
              class="select-default"
              v-model="filters.carrier"
              clearable
              placeholder="Carrier">
              <el-option
                v-for="item in filterBuilders.carriers"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group col-md-2 col-sm-12">
            <el-select
              class="select-default"
              v-model="filters.status"
              clearable
              placeholder="Shipping Status">
              <el-option
                v-for="item in filterBuilders.shippingStatus"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="form-group col-md-2 col-sm-12">
            <el-select
              class="select-default"
              v-model="filters.country"
              clearable
              placeholder="Country">
              <el-option
                v-for="item in filterBuilders.countries"
                :key="item.label"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="block form-group col-md-2 col-sm-12">
            <el-date-picker
              v-model="filters.shipFrom"
              type="date"
              placeholder="Ship From">
            </el-date-picker>
          </div>

          <div class="block form-group col-md-2 col-sm-12">
            <el-date-picker
              v-model="filters.shipTo"
              type="date"
              placeholder="Ship To">
            </el-date-picker>
          </div>
        </div>

        <div class="col-12 flex-wrap d-flex mb-3">
          <l-button class="mr-lg-1" @click="getData('filterData')" type="info">Filter Result</l-button>

          <l-button
            v-if="$store.getters['auth/checkAccess']('projects-shipping-data/export-data')"
            @click="downloadExcel()"
            class="mr-lg-1"
            type="info">
             <span style="margin-right: 3px" class="btn-label">
                    <i class="fa fa-file"></i>
             </span>
            Export Data
          </l-button>
        </div>
        <!--   end filters     -->

        <!--  table -->
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <el-select
            class="select-default mb-3"
            style="width: 200px"
            v-model="paginationData.pageSize"
            @change="getData('changePageSize')"
            placeholder="Per page">
            <el-option
              class="select-default"
              v-for="item in paginationData.perPageOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <el-input type="search"
                    class="mb-3"
                    style="width: 200px"
                    placeholder="Search records"
                    v-model="searchQuery"
                    @change="getData('updateSearch')"
                    aria-controls="datatables"/>
        </div>

        <div class="col-sm-12">
          <el-table stripe
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                    style="width: 100%;"
                    :data="tableData"
                    border>

            <el-table-column
              type="selection"
              align="center"
              width="60">
            </el-table-column>

            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             align="center"
                             :prop="column.prop"
                             :label="column.label"
                             :formatter="column.formatter !== null ? column.formatter : null">

              <template slot-scope="scope">

                <el-tag v-if="column.prop === 'status'"
                        :type="getStatusColor(scope.row[column.prop])"
                        size="medium">{{ scope.row[column.prop] }}
                </el-tag>

                <el-tag v-else-if="column.prop === 'is_active'"
                        :type="scope.row[column.prop] ? 'success' : 'danger'"
                        size="medium">{{ scope.row[column.prop] ? 'active' : 'disabled' }}
                </el-tag>

                <span v-else-if="column.prop === 'country'">
                  {{ scope.row[column.prop] != null ? scope.row[column.prop].name : "" }}
                </span>

                <span v-else>{{ scope.row[column.prop] }}</span>

              </template>

            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="props">
                <router-link v-if="$store.getters['auth/checkAccess']('projects-shipping-data/get-row')"
                             v-tooltip.top-center="'View'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/projects/update-shipping-data/'+props.row.id"><i
                  class="fa fa-eye"></i></router-link>
              </template>
            </el-table-column>


          </el-table>
        </div>

        <div slot="footer">
          <NLPagination class="pagination-no-border"
                        v-model="paginationData.currentPage"
                        @input="getData('changePageNumber')"
                        :per-page="paginationData.pageSize"
                        :total="paginationData.totalItems">
          </NLPagination>
        </div>

        <!--  End table -->

      </card>


    </div>

    <delete-all-modal :visible="deleteModalVisibility"
                      @close="closeDeleteAllModal()"
                      @confirm="confirmDeleteAllModal()">
    </delete-all-modal>

    <change-packages-status
      :visible="changeStatusModalVisibility"
      :statuses="filterBuilders.shippingStatus"
      @close="closeChangeStatusModal()"
      @confirm="confirmChangeStatusModal">
    </change-packages-status>


  </div>
</template>
<script>
import {Table, TableColumn, Select, Option, Tag, DatePicker, Popconfirm, Popover, Button} from 'element-ui'
import {NewPagination as NLPagination} from 'src/components/index'
import DeleteAllModal from "@/components/DeleteAllModal";
import ChangePackageStatus from "@/components/ChangePackageStatus";
import ChangePackagesStatus from "@/components/ChangePackageStatus";

export default {
  components: {
    ChangePackagesStatus,
    NLPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag,
    [DatePicker.name]: DatePicker,
    [Popconfirm.name]: Popconfirm,
    [Popover.name]: Popover,
    [Button.name]: Button,
    DeleteAllModal,
    ChangePackageStatus
  },

  data() {

    return {
      loader: {},
      deleteModalVisibility: false,
      changeStatusModalVisibility: false,
      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0
      },
      searchQuery: '',
      tableColumns: [
        {
          prop: 'order_number',
          label: 'Order Number',
          minWidth: 100
        },
        {
          prop: 'packages_number',
          label: 'Packages #',
          minWidth: 100
        },
        {
          prop: 'is_active',
          label: 'Activation Status',
          minWidth: 130
        },
        {
          prop: 'recipient_name',
          label: 'Recipient Name',
          minWidth: 100
        },
        {
          prop: "country",
          label: 'Country',
          minWidth: 100
        },
        {
          prop: 'ship_date',
          label: 'Shipping Date',
          minWidth: 100
        },
        {
          prop: 'carrier',
          label: 'Carrier',
          minWidth: 100
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 130,
        },
        {
          prop: 'tracking_number',
          label: 'Tracking #',
          minWidth: 130
        },

      ],
      tableData: [],
      filterBuilders: {
        activationStatus: [],
        carriers: [],
        shippingStatus: [],
        countries: [],
      },
      filters: {
        activationStatus: null,
        carrier: null,
        shipFrom: null,
        shipTo: null,
        country: null,
        status: null,
      },
      selectedRows: [],
      preventActions: false
    }

  },

  methods: {

    getData(action) {
      switch (action) {
        case 'bulkActions':
          this.updateCurrentPage(1);
          this.resetFilters();
          break;
        case 'filterData':
          this.updateCurrentPage(1);
          break;
        case 'updateSearch':
          this.updateCurrentPage(1);
          break;
        case 'changePageSize':
          this.updateCurrentPage(1);
          break;
        case 'changePageNumber' :
          break;
      }

      this.loader = this.$loading.show({
        container: this.$refs.table
      });

      this.axios.post("projects-shipping-data/get-data/" + this.$route.params['id'] +
        "?page=" + this.paginationData.currentPage + "&pageSize=" + this.paginationData.pageSize,
        {searchQuery: this.searchQuery, filters: this.filters}).then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    updateCurrentPage(pageNumber) {
      this.paginationData.currentPage = pageNumber;
    },

    getStatusColor(status) {
      let classes = {
        "Label Created": "info",
        "In Transit": "",
        "Delivered": "success",
        "Pending Recipient Action": "warning",
        "Undeliverable": "danger",
      }

      return classes[status];
    },

    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows.map((row) => {
        return row.id;
      });
    },

    async activateSelected() {
      try {
        this.preventActions = true;
        let response = await this.axios.post("projects-shipping-data/activate-records/" +
          this.$route.params['id'], {ids: this.selectedRows});
        this.getData('bulkActions');
        this.preventActions = false;
      } catch (e) {
        console.log(e);
      }
    },

    async deactivatedSelected() {
      try {
        this.preventActions = true;
        let response = await this.axios.post("projects-shipping-data/deactivate-records/" +
          this.$route.params['id'], {ids: this.selectedRows});
        this.getData('bulkActions');
        this.preventActions = false;
      } catch (e) {
        console.log(e);
      }
    },

    async deleteSelected() {
      try {
        this.preventActions = true;
        let response = await this.axios.post("projects-shipping-data/delete-records/" +
          this.$route.params['id'], {ids: this.selectedRows});
        this.getData('bulkActions');
        this.preventActions = false;
      } catch (e) {
        console.log(e);
      }
    },

    resetFilters() {
      this.filters = {
        activationStatus: null,
        carrier: null,
        shipFrom: null,
        shipTo: null,
        country: null,
        status: null,
      }
    },

    openDeleteAllModal() {
      this.deleteModalVisibility = true;
    },

    closeDeleteAllModal() {
      this.deleteModalVisibility = false;
    },

    openChangeStatusModal() {
      this.changeStatusModalVisibility = true;
    },

    closeChangeStatusModal() {
      this.changeStatusModalVisibility = false;
    },

    async confirmDeleteAllModal() {
      try {
        this.preventActions = true;
        let response = await this.axios.post("projects-shipping-data/delete-all-records/" +
          this.$route.params['id'], {});
        this.getData('bulkActions');
        this.preventActions = false;
        this.closeDeleteAllModal();
      } catch (e) {
        console.log(e);
      }
    },

    async confirmChangeStatusModal(obj) {
      try {
        this.preventActions = true;
        let response = await this.axios.post("projects-shipping-data/change-status/" +
          this.$route.params['id'], {...obj, ids: this.selectedRows});
        this.getData('bulkActions');
        this.preventActions = false;
        this.closeChangeStatusModal();
      } catch (e) {
        console.log(e);
      }
    },

    downloadExcel() {
      let fileName = 'Project Data ' + new Date().toLocaleDateString() + ".xlsx";
      this.axios.post("projects-shipping-data/export-data/" + this.$route.params['id'],
        {searchQuery: this.searchQuery, filters: this.filters}, {responseType: 'blob'})
        .then((response) => {
          let url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        });
    }

  },

  async mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.table
    });
    let response = await this.axios.get("projects-shipping-data/get-data/builder/" + this.$route.params['id']);
    this.filterBuilders = response.data.filters;
    this.loader.hide();
    this.getData();
  },

  computed: {
    numberOfSelectedRows() {
      return this.selectedRows.length;
    }
  },

}
</script>
<style>
</style>
