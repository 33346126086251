<template>

  <div class="row" ref="projectForm">

    <div class="col-xs-12 col-md-12 col-sm-12">

      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <fieldset>
              <legend>General Info</legend>
              <div class="row">
                <div class="col-xs-6 col-md-6 col-sm-12">
                  <ValidationProvider
                    vid="p_id"
                    rules="required"
                    name="The Project Id"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Project Id"
                              name="p_id"
                              fou
                              v-model="formData.p_id">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    vid="title"
                    rules="required"
                    name="The title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Title"
                              name="title"
                              fou
                              v-model="formData.title">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    vid="date"
                    rules="required"
                    name="The date"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="date"
                              :error="failed ? errors[0]: null"
                              label="Date"
                              name="date"
                              fou
                              v-model="formData.date">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    vid="description"
                    rules="required"
                    name="The description"
                    v-slot="{ passed, failed,errors }">
                    <fg-text type="text"
                             :error="failed ? errors[0]: null"
                             label="Description"
                             name="description"
                             :rows="5"
                             fou
                             v-model="formData.description">
                    </fg-text>
                  </ValidationProvider>

                  <div class="form-group">
                    <label>Project Thumbnail</label>
                    <el-tooltip placement="right">
                      <div slot="content">{{getProjectThumbnailInfo()}}</div>
                      <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                    </el-tooltip>
                    <image-uploader :number-of-files="1"
                                    :input-list="logoImages"
                                    @change="logoChanged">
                    </image-uploader>
                  </div>
                </div>
                <div class="col-xs-6 col-md-6 col-sm-12">
                  <fg-input type="text"
                            label="Proof Link"
                            name="proof_link"
                            fou
                            v-model="formData.proof_link">
                  </fg-input>

                  <ValidationProvider
                    vid="number_of_packages"
                    rules="required"
                    name="Total Number Of Packages"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="number"
                              min="0"
                              :error="failed ? errors[0]: null"
                              label="Number Of Packages"
                              name="number_of_packages"
                              fou
                              v-model="formData.number_of_packages">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    vid="company_id"
                    rules="required"
                    name="The Company"
                    v-slot="{ passed, failed,errors }">
                    <div class="form-group">
                      <label>Related Company</label>
                      <el-select class="select-default"
                                 size="large"
                                 v-model="formData.company_id"
                                 label="company"
                                 filterable
                                 placeholder="Select Company">
                        <el-option v-for="option in companies"
                                   class="select-default"
                                   :value="option.id"
                                   :label="option.name"
                                   :key="option.id">
                        </el-option>
                      </el-select>

                      <div class="text-danger invalid-feedback" style="display: block;">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                  <div class="form-group">
                    <l-switch v-model="formData.is_active">
                      <i class="fa fa-check" slot="on"></i>
                      <i class="fa fa-times" slot="off"></i>
                    </l-switch>&nbsp;
                    <label>Is Active</label>
                  </div>

                  <div class="form-group">
                    <l-switch v-model="formData.is_complete">
                      <i class="fa fa-check" slot="on"></i>
                      <i class="fa fa-times" slot="off"></i>
                    </l-switch>&nbsp;
                    <label>Is Complete</label>
                  </div>

                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>Project Documents</legend>
              <div class="row">
                <div class="col-xs-12 col-md-12 col-sm-12">
                  <project-documents v-bind:file-list="formData.fileList"></project-documents>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(() => submit(false))"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import {ImageUploader, Switch as LSwitch} from 'src/components/index'
import ProjectDocuments from "@/pages/Dashboard/Projects/ProjectDocuments";
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    ProjectDocuments,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    LSwitch,
    ImageUploader
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      logoImages: [],

      formData: {
        p_id: "",
        title: "",
        date: "",
        description: "",
        company_id: "",
        proof_link: "",
        number_of_packages: null,
        fileList: [],
        is_active: true,
        is_complete: false,
        logo: ""
      },

      companies: [],
    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.projectForm
    });

    this.axios.get("projects/builder").then((response) => {
      this.companies = response.data.companies;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {

        this.editMode = true;
        this.formTitle = "Edit Project";
        this.getProject();

      } else {
        this.editMode = false;
        this.formTitle = "Add Project";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {


    getProject() {
      this.axios.get("projects/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.prepareLogoImage();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Project Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit(uploadData) {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("projects/update/" + this.id, this.formData);
        successMessage = "Project Updated Successfully";
      } else {
        request = this.axios.post("projects/create", this.formData);
        successMessage = "Project Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        });

        if (uploadData) {
          this.$router.push("/projects/upload-data/" + response.data.id);
        } else {
          this.$router.push("/projects/list");
        }

      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    },

    prepareLogoImage() {
      if (this.formData.logo) {
        this.logoImages = [{path: this.formData.logo}];
      } else {
        this.logoImages = [];
      }
    },

    logoChanged(images) {
      this.formData.logo = images.length > 0 ? images[0].path : null;
    },

    getProjectThumbnailInfo() {
      if(this.mediaInfo["project_thumbnail"]) {
        return this.mediaInfo["project_thumbnail"];
      } else {
        return "";
      }
    },

  }
}
</script>

<style>
</style>
