<template>

  <div>

    <div class="upload-component d-flex justify-content-center">
      <el-upload
        class="upload-demo"
        drag
        :action="uploadUrl"
        :headers="headers"
        :name="fileName"
        :on-success="onSuccess"
        multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      </el-upload>
    </div>


    <table class="table table-bordered">

      <thead>
      <tr>
        <th>Document Description</th>
        <th>File Name</th>
        <th>Download</th>
        <th>Actions</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(file,i) in fileList">
        <td>
          <input type="text" class="form-control" v-model="file.document_description">
        </td>

        <td>
          {{ file.file_name }}
        </td>

        <td>
          <a href="javascript:void(0);" @click="download(file.file_name,file.file_path)">download</a>
        </td>

        <td>
          <l-button type="danger"
                    @click="removeDocument(i)" size="sm">
             <span class="btn-label">
                    <i class="fa fa-times"></i>
                </span>
          </l-button>
        </td>

      </tr>
      </tbody>
    </table>

  </div>

</template>
<script>

import {Upload} from 'element-ui';

export default {

  components: {
    [Upload.name]: Upload
  },

  props: {
    fileList: {
      type: Array,
      required: true
    }
  },

  watch: {
    fileList(value) {
      this.$emit('input', value)
    }
  },


  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_URL + 'projects/upload-document',
      headers: {
        'Authorization': "Bearer " + this.$store.getters["auth/token"]
      },
      fileName: 'file',
      showFileList: false,
    };
  },

  methods: {
    onSuccess(response, file, fileList) {
      this.fileList.push({document_description: "", file_name: file.name, file_path: file.response.data.url})
    },

    removeDocument(index) {
      this.fileList.splice(index, 1);
    },

    download(fileName, filePath) {
      this.axios.post("projects/download-document",
        {"path": filePath},
        {responseType: 'blob'})
        .then(response => {
          let blob = new Blob([response.data], {type: response.headers['content-type']})
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click()
        })
    }
  }
}
</script>

<style scoped lang="scss">

.table > thead > tr > th, .el-table__body > thead > tr > th {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-weight: 600;
  width: 25%;
}

.table tbody td:first-child, .table thead th:first-child {
  padding: 10px;
}

tr {
  text-align: center;
}


.card .table tbody td:last-child, .card .table thead th:last-child {
  display: revert;
}

.upload-component {
  padding: 30px;
}
</style>
