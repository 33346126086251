<template>

  <div class="row" ref="projectForm">

    <div class="col-xs-12 col-md-12 col-sm-12">

      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <fieldset>
              <legend>General Info</legend>
              <div class="row">
                <div class="col-xs-6 col-md-6 col-sm-12">

                  <fg-input type="text"
                            label="Order Number"
                            name="order_number"
                            fou
                            v-model="formData.order_number">
                  </fg-input>

                  <fg-input type="number"
                            label="Packages Number"
                            name="packages_number"
                            min="0"
                            fou
                            v-model="formData.packages_number">
                  </fg-input>

                  <fg-input type="date"
                            label="Order Date"
                            name="order_date"
                            fou
                            v-model="formData.order_date">
                  </fg-input>

                  <fg-input type="date"
                            label="Ship Date"
                            name="ship_date"
                            fou
                            v-model="formData.ship_date">
                  </fg-input>

                  <fg-input type="date"
                            label="Date Received"
                            name="date_received"
                            fou
                            v-model="formData.date_received">
                  </fg-input>

                  <fg-input type="text"
                            label="Order Source"
                            name="order_source"
                            fou
                            v-model="formData.order_source">
                  </fg-input>

                  <fg-input type="text"
                            label="Customer Email"
                            name="customer_email"
                            fou
                            v-model="formData.customer_email">
                  </fg-input>

                </div>
                <div class="col-xs-6 col-md-6 col-sm-12">

                  <div class="form-group">
                    <label>Status</label>
                    <el-select
                      class="select-default"
                      v-model="formData.status"
                      clearable
                      placeholder="Shipping Status">
                      <el-option
                        v-for="item in shippingStatus"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>


                  <fg-input type="date"
                            label="Delivery Date"
                            name="delivery_date"
                            fou
                            v-model="formData.delivery_date">
                  </fg-input>

                  <fg-input type="text"
                            label="Weight (Oz)"
                            name="weight"
                            fou
                            v-model="formData.weight">
                  </fg-input>

                  <fg-input type="number"
                            min="0"
                            label="Amount Paid"
                            name="amount_paid"
                            fou
                            v-model="formData.amount_paid">
                  </fg-input>

                  <fg-input type="number"
                            min="0"
                            label="Insured Value"
                            name="insured_value"
                            fou
                            v-model="formData.insured_value">
                  </fg-input>

                  <fg-input type="number"
                            min="0"
                            label="Insurance Cost"
                            name="insurance_cost"
                            fou
                            v-model="formData.insurance_cost">
                  </fg-input>

                  <fg-input type="text"
                            label="Insurance Provider"
                            name="insurance_provider"
                            fou
                            v-model="formData.insurance_provider">
                  </fg-input>

                  <div class="form-group">
                    <l-switch
                      v-model="formData.is_active">
                      <i class="fa fa-check" slot="on"></i>
                      <i class="fa fa-times" slot="off"></i>
                    </l-switch>&nbsp;
                    <label>Is Active</label>
                  </div>

                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>Recipient Info</legend>
              <div class="row">
                <div class="col-xs-6 col-md-6 col-sm-12">

                  <fg-input type="text"
                            label="Recipient Name"
                            name="recipient_name"
                            fou
                            v-model="formData.recipient_name">
                  </fg-input>

                  <fg-input type="text"
                            label="Recipient Phone"
                            name="recipient_phone"
                            fou
                            v-model="formData.recipient_phone">
                  </fg-input>

                  <fg-input type="text"
                            label="Recipient Company"
                            name="recipient_company"
                            fou
                            v-model="formData.recipient_company">
                  </fg-input>

                  <fg-input type="text"
                            label="Recipient Address 1"
                            name="recipient_address_1"
                            fou
                            v-model="formData.recipient_address_1">
                  </fg-input>

                  <fg-input type="text"
                            label="Recipient Address 2"
                            name="recipient_address_2"
                            fou
                            v-model="formData.recipient_address_2">
                  </fg-input>

                  <fg-input type="text"
                            label="Recipient Address 3"
                            name="recipient_address_3"
                            fou
                            v-model="formData.recipient_address_3">
                  </fg-input>


                </div>

                <div class="col-xs-6 col-md-6 col-sm-12">

                  <div class="form-group">
                    <label>Country</label>
                    <el-select
                      class="select-default"
                      v-model="formData.country_id"
                      filterable
                      clearable
                      placeholder="Please enter a keyword"
                      reserve-keyword
                      remote
                      :remote-method="getCountries">
                      <el-option
                        v-for="item in countries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <div class="form-group">
                    <label>State</label>
                    <el-select
                      class="select-default"
                      v-model="formData.state_id"
                      filterable
                      clearable
                      placeholder="Please enter a keyword"
                      reserve-keyword
                      remote
                      :remote-method="getStates">
                      <el-option
                        v-for="item in states"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <div class="form-group">
                    <label>City</label>
                    <el-select
                      class="select-default"
                      v-model="formData.city_id"
                      filterable
                      clearable
                      placeholder="Please enter a keyword"
                      reserve-keyword
                      remote
                      :remote-method="getCities">
                      <el-option
                        v-for="item in cities"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <fg-input type="text"
                            label="Zip Code"
                            name="recipient_zip_code"
                            fou
                            v-model="formData.recipient_zip_code">
                  </fg-input>

                  <fg-input type="text"
                            label="Residential Flag"
                            name="recipient_residential_flag"
                            fou
                            v-model="formData.recipient_residential_flag">
                  </fg-input>

                </div>

              </div>
            </fieldset>


            <fieldset>
              <legend>Carrier Info</legend>
              <div class="row">
                <div class="col-xs-6 col-md-6 col-sm-12">

                  <div class="form-group">
                    <label>Carrier</label>
                    <el-select
                      class="select-default"
                      v-model="formData.carrier"
                      clearable
                      placeholder="Carrier">
                      <el-option
                        v-for="item in carriers"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>

                  <fg-input type="text"
                            label="Class Service"
                            name="class_service"
                            fou
                            v-model="formData.class_service">
                  </fg-input>

                  <fg-input type="number"
                            min="0"
                            label="Carrier Fee"
                            name="carrier_fee"
                            fou
                            v-model="formData.carrier_fee">
                  </fg-input>


                </div>
                <div class="col-xs-6 col-md-6 col-sm-12">
                  <fg-input type="text"
                            label="Tracking Number"
                            name="tracking_number"
                            fou
                            v-model="formData.tracking_number">
                  </fg-input>

                </div>
              </div>
            </fieldset>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="submitting " @click.prevent="submit"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/projects/shipping-file-list/'+formData.project_id)" type="danger" wide>
              Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import {Switch as LSwitch} from 'src/components/index'
import ProjectDocuments from "@/pages/Dashboard/Projects/ProjectDocuments";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    ProjectDocuments,
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch
  },

  data() {
    return {
      loader: {},
      projectId: undefined,

      formTitle: "",
      submitting: false,

      carriers: [],
      shippingStatus: [],

      countries: [],
      states: [],
      cities: [],

      formData: {
        project_id: null,
        order_number: null,
        packages_number: null,
        order_date: null,
        order_source: null,
        ship_date: null,
        date_received: null,
        amount_paid: null,
        insured_value: null,
        insurance_cost: null,
        insurance_provider: null,
        is_active: true,
        status: null,
        delivery_date: null,
        weight: null,
        customer_email: null,


        carrier: null,
        carrier_fee: null,
        class_service: null,
        tracking_number: null,

        recipient_name: null,
        recipient_phone: null,
        recipient_company: null,
        recipient_address_1: null,
        recipient_address_2: null,
        recipient_address_3: null,
        recipient_zip_code: null,
        recipient_city: null,
        recipient_state: null,
        recipient_country: null,
        recipient_residential_flag: null,
        country_id: null,
        city_id: null,
        state_id: null,
      },
    };
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.projectForm
    });
    this.projectId = this.$route.params['projectId'];
    this.formTitle = "Add Form";
    this.formData.project_id = this.projectId;
    this.builder();
  },


  methods: {
    builder() {
      this.axios.get("projects-shipping-data/add-row-builder").then((response) => {
        this.carriers = response.data.carriers;
        this.shippingStatus = response.data.shippingStatus;
        this.loader.hide();
      }).catch((error) => {
        console.log(error);
      });
    },

    getCountries(query) {
      if (query !== '') {
        this.axios.get("global/countries-search?search=" + query).then((response) => {
          this.countries = response.data;
        });
      } else {
        this.countries = [];
      }
    }
    ,

    getStates(query) {
      if (query !== '') {
        this.axios.get("global/states-search?search=" + query).then((response) => {
          this.states = response.data;
        });
      } else {
        this.states = [];
      }
    },

    getCities(query) {
      if (query !== '') {
        this.axios.get("global/cities-search?search=" + query).then((response) => {
          this.cities = response.data;
        });
      } else {
        this.cities = [];
      }
    },

    submit() {
      this.submitting = true;
      let request = this.axios.post("projects-shipping-data/add-row/", this.formData).then((response) => {
        this.$notify({
          message: "Record added successfully",
          timeout: 1000,
          type: 'success'
        });
        this.$router.push('/projects/shipping-file-list/' + this.formData.project_id);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          this.$notify({
            message: error.response.data.message,
            timeout: 4000,
            type: 'danger'
          });
        }
      }).finally(() => {
        this.submitting = false;
      });
    }
  },

}
</script>

<style>
</style>
