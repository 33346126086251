<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <h5 style="text-align: center">Choose Status:</h5>

    <div class="form-group">
      <el-select
        class="select-default"
        v-model="status"
        placeholder="Shipping Status">
        <el-option
          v-for="item in statuses"
          :key="item.label"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>

    <fg-input type="date" label="Delivery Date:" v-if="status === 'Delivered'"
              v-model="deliveryDate"></fg-input>

    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog, Select, Option} from 'element-ui'

export default {
  name: 'change-packages-status',

  components: {
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      status: 'In Transit',
      deliveryDate: new Date().toISOString().slice(0,10)
    }
  },

  props: {

    visible: {
      type: Boolean,
      default: 0
    },

    statuses: {
      type: Array
    }
  },

  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      const obj = {"status": this.status, "deliveryDate": this.deliveryDate};
      this.$emit('confirm', obj);
    }

  }

}
</script>
