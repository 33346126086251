<template>
  <div class="row" ref="table">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Source Data</h4>
          <l-button
            @click="downloadExcel()"
            type="info" wide>
             <span style="margin-right: 3px" class="btn-label">
                    <i class="fa fa-file"></i>
             </span>
            Export
          </l-button>
        </div>


        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="paginationData.pageSize"
              @change="getData('changePageSize')"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in paginationData.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <el-input type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="Search records"
                      v-model="searchQuery"
                      @change="getData('updateSearch')"
                      aria-controls="datatables"/>
          </div>

          <div class="col-sm-12">
            <el-table stripe
                      style="width: 100%;"
                      :data="tableData"
                      border>

              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                               align="center"
                               :prop="column.prop"
                               :label="column.label"
                               :formatter="column.formatter !== null ? column.formatter : null">
              </el-table-column>
            </el-table>
          </div>


        </div>

        <div slot="footer">
          <NLPagination class="pagination-no-border"
                        v-model="paginationData.currentPage"
                        @input="getData('changePageNumber')"
                        :per-page="paginationData.pageSize"
                        :total="paginationData.totalItems">
          </NLPagination>
        </div>

      </card>

    </div>

  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'

export default {
  components: {
    NLPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },

  data() {

    return {
      loader: {},
      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0
      },

      searchQuery: '',

      tableColumns: [],

      tableData: [],
    }

  },

  async mounted() {
    let response = await this.getTableColumn();
    this.tableColumns = response.data.tableColumns;
    this.tableColumns.map(c => {
      c.minWidth = 200;
    });
    this.getData();
  },

  methods: {

    async getTableColumn() {
      return await this.axios.get("projects-source-data/get-data/builder/" + this.$route.params['id']);
    },

    getData
      (action) {
      switch (action) {
        case 'updateSearch':
          this.updateCurrentPage(1);
          break;
        case 'changePageSize':
          this.updateCurrentPage(1);
          break;
        case 'confirmDelete':
          this.searchQuery = '';
          this.updateCurrentPage(1);
          break;
        case 'changePageNumber' :
          break;
      }

      this.loader = this.$loading.show({
        container: this.$refs.table
      });

      this.axios.get("projects-source-data/get-data/" + this.$route.params['id'] + "?page=" + this.paginationData.currentPage +
        "&pageSize=" + this.paginationData.pageSize +
        "&searchQuery=" + this.searchQuery).then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    updateCurrentPage(pageNumber) {
      this.paginationData.currentPage = pageNumber;
    },

    downloadExcel() {
      let fileName = 'Project Source Data ' + new Date().toLocaleDateString() + ".xlsx";
      this.axios.get("projects-source-data/export-data/" + this.$route.params['id'],
        {responseType: 'blob'})
        .then((response) => {
          let url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        });
    }
  },

}
</script>
<style>
</style>
